import { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Grid, Typography } from '@mui/material';
import { UploadOnRemoveEvent, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';

import { IMAGE_EXTENSIONS, TWINT_LOGO_METADATA, URLS } from '../../../../constants';
import { FormControlLabelComponent, FormInput, UploadFiles } from '../../../common';
import { InputType, InputValue } from '../../../common/interfaces';
import {
    createDeleteUrl,
    createUploadUrl,
    getDefaultFilesInfo,
    translateTerm,
} from '../../../../helpers';
import { GeneralTabProps, ModuleField, ModuleFormValues } from '../../interfaces';

interface ModuleConfigurationTabInterface extends GeneralTabProps<ModuleFormValues> {
    componentName: string;
    moduleFields: ModuleField[] | [];
    selectedMandatorId: string;
}

/**
 * Module configuration tab component.
 *
 * @param {{ formProps: { values: ModuleFormValues, setFieldValue: (name: string, value: any) => void }, selectedMandatorId: string, componentName: string, isDisabled: boolean }} props
 * @param {ModuleField[]} props.moduleFields - module fields
 * @returns {JSX.Element} - component JSX
 */
const ModuleConfigurationTab = ({
    moduleFields,
    formProps,
    selectedMandatorId,
    componentName,
    isDisabled,
}: ModuleConfigurationTabInterface): JSX.Element => {
    const { t } = useTranslation();
    const { values, errors, touched, setFieldValue, handleChange, handleBlur } = formProps;

    const getFieldLabel = useCallback(
        (fieldName: string) => {
            return translateTerm(`marketplace.fields.${fieldName}`, fieldName, t);
        },
        [t]
    );

    const uploadSaveUrl = createUploadUrl(
        `${URLS.ManageFiles}`,
        TWINT_LOGO_METADATA,
        componentName,
        selectedMandatorId
    );
    const removeUrl = useCallback(
        (field_name: string) => {
            return createDeleteUrl(`${URLS.ManageFiles}`, values[field_name] as string);
        },
        [values]
    );

    const onFileChange = (data: UploadOnStatusChangeEvent, fieldName: string) => {
        if (data.response) {
            const backendResponse = data.response.response;
            setFieldValue(fieldName, backendResponse?.data[0]?.access_file_path);
        }
    };
    const onFileRemoval = (data: UploadOnRemoveEvent, fieldName: string) => {
        const response = data?.response?.response;
        if (response && response.data?.length === 1) {
            setFieldValue(fieldName, '');
        }
    };

    const generateFormField = (field: ModuleField) => {
        const { type, name } = field;
        const fieldValue = type !== 'number' ? (values[name] ?? '') : values[name];

        switch (type) {
            case 'boolean':
                return (
                    <FormControl className="formControl switchInput" variant="outlined">
                        <Typography variant="h3">{getFieldLabel(name)}</Typography>
                        <FormControlLabelComponent
                            active={JSON.parse(fieldValue as string)}
                            onChange={(event) => {
                                event.target.value = (
                                    event.target.value.length > 0 ? true : false
                                ).toString();
                                handleChange(event);
                            }}
                            size="medium"
                            color="success"
                            name={name}
                            control={'switch'}
                            disabled={isDisabled}
                        />
                    </FormControl>
                );
            case 'file':
                return (
                    <FormControl className="formControl" sx={{ maxWidth: '25rem' }}>
                        <Typography variant="h3">{getFieldLabel(name)}</Typography>
                        <UploadFiles
                            saveUrl={uploadSaveUrl}
                            removeUrl={removeUrl(name)}
                            fileMetaData={TWINT_LOGO_METADATA}
                            allowedExtensions={IMAGE_EXTENSIONS}
                            withCredentials={false}
                            isDisabled={isDisabled}
                            onChange={(event: UploadOnStatusChangeEvent) => {
                                onFileChange(event, name);
                            }}
                            onRemove={(event: UploadOnRemoveEvent) => onFileRemoval(event, name)}
                            defaultFiles={
                                values[name] ? getDefaultFilesInfo(values[name] as string) : []
                            }
                        />
                    </FormControl>
                );
            default:
                return (
                    <FormInput
                        label={getFieldLabel(name)}
                        fieldName={name}
                        type={field.type as InputType}
                        value={fieldValue as InputValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        disabled={isDisabled}
                        errors={errors}
                        touched={touched}
                    />
                );
        }
    };

    return (
        <Grid item xs={12} sm={8} lg={6} xl={4} sx={{ display: 'flex', flexDirection: 'column' }}>
            {moduleFields.map((field: ModuleField, index: number) => (
                <Fragment key={index}>{generateFormField(field)}</Fragment>
            ))}
        </Grid>
    );
};
export default ModuleConfigurationTab;
