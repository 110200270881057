import localforage from 'localforage';
import { EventEmitter } from 'events';
import {
    GeneralUserPreferences,
    UserPreferencesForDashboard,
    RemoveUserPreferencesDataProps,
    UserPreferencesProps,
} from '../app/pages/interfaces';
import { GridNames, ToasterType } from '../constants';
import { showToaster } from '.';
import i18n from '../i18n';
import { CustomDataState } from '../app/common/interfaces';

export const localForageEmitter = new EventEmitter();

/**
 * Removes user preferences from localforage storage asynchronously.
 *
 * @param {string} key - The key associated with the data to be removed.
 * @param {Dispatch<SetStateAction<null>>} props.resetData - A function that is used to reset the state for user preferences
 * @returns {Promise<void>} A promise that resolves once the item is removed and the data is reset.
 *
 * @throws Will display a toaster error notification if the removal fails.
 */
export const removeUserPreferencesData = async ({
    key,
    resetData,
}: RemoveUserPreferencesDataProps): Promise<void> => {
    try {
        // Remove the item from local storage asynchronously
        await localforage.removeItem(key);
        resetData?.({});
        localForageEmitter.emit('dataRemoved');
    } catch (err) {
        // Handle any errors that occurred during removal
        showToaster(
            ToasterType.Error,
            i18n.t('general.messages.localForageRemoveAction', {
                item: key,
            }),
            '',
            true
        );
    }
};

/**
 * General method that asynchronously retrieves user preferences data from local forage based on the provided key.
 * @param {`${GridNames}`} key - The key associated with the user preferences to be fetched.
 *
 * @returns {Promise<GeneralUserPreferences | UserPreferencesForDashboard | null>} A promise that resolves to the user preferences object.
 *          The object can be of type `GeneralUserPreferences`, `UserPreferencesForDashboard`, or `null` if no preferences are found or an error occurs.
 *
 * @throws Will show a toaster notification if there is an error during retrieval.
 */
export const getUserPreferences = async (
    key: `${GridNames}`
): Promise<GeneralUserPreferences | UserPreferencesForDashboard | null> => {
    try {
        // Fetch the item from local storage asynchronously
        const value = await localforage.getItem(key);
        return value as GeneralUserPreferences | UserPreferencesForDashboard | null;
    } catch (err) {
        // Handle any errors that occurred during retrieval
        showToaster(ToasterType.Error, (err as string) ?? '', '', true);
        return null;
    }
};

/**
 * General function that stores user preferences data in localforage based on a key
 * @param {`${GridNames}`} key - The key associated with the data to be stored
 * @param {PreservedState} state - The grid’s state to be saved
 * @param {string[]} props.columns -  The columns to be saved
 * @param {DashboardSelectValues} props.dashboardSelectedCharts - The Dashboard selected charts
 * @param {string} props.searchInputValue - The search input by which the user filters the grid data
 * @param {string[]} props.transactionStatus - The list of selected transaction statuses
 * @param {SelectionRange} props.time - Start and end time of the selected time range
 * @param {boolean} props.showCharts - Indicates if the charts should be shown
 * @returns {Promise<void>} A promise that resolves to localForage data updated
 */

export const saveOrUpdateUserPreferences = async (
    key: `${GridNames}`,
    {
        state,
        filter,
        columns,
        dashboardSelectedCharts,
        searchInputValue,
        selectedItems,
        time,
        showCharts,
        transactionStatus,
    }: UserPreferencesProps
): Promise<void> => {
    const objectToSave: GeneralUserPreferences | UserPreferencesForDashboard = {
        columns,
        state,
        showCharts,
        filter,
        ...(searchInputValue !== undefined && { searchInputValue }),
        ...(dashboardSelectedCharts && { dashboardSelectedCharts }),
        ...(selectedItems && { selectedItems }),
        ...(transactionStatus !== undefined && { transactionStatus }),
        ...(time !== undefined && { time }),
    };
    try {
        const item: GeneralUserPreferences | UserPreferencesForDashboard | null =
            await localforage.getItem(key);
        if (!item) {
            await localforage.setItem(key, objectToSave);
        } else {
            const updatedItem = updateStorageItem(item, objectToSave);
            await localforage.setItem(key, updatedItem);
        }
        localForageEmitter.emit('dataChanged');
    } catch (err) {
        console.error('Error updating or adding item:', err);
        return;
    }
};

/**
 * Removes user preferences data from local forage if all values are nullish (null or undefined)
 * @param {`${GridNames}`} gridName - The grid name
 * @returns {Promise<boolean>} true if the object was removed, false otherwise
 */
export const removeUserPreferencesDataForNullish = async (
    gridName: `${GridNames}`
): Promise<boolean> => {
    const isNullishObject = await checkNullishObject(gridName);

    // set grid name if the object has values or undefined otherwise
    if (isNullishObject) {
        removeUserPreferencesData({ key: gridName });
        return true;
    }
    return false;
};

/**
 * Removes all preserved selected items for all grids, and removes the user preferences if all values are nullish.
 * This function is used to reset the user preferences when the user logs out.
 * @returns {Promise<void>} A promise that resolves to void.
 */
export const removePreservedSelectedItems = async (): Promise<void> => {
    const localForageKeys = (await localforage.keys()) as `${GridNames}`[];
    localForageKeys.forEach(async (key) => {
        await saveOrUpdateUserPreferences(key, { selectedItems: [] });
        removeUserPreferencesDataForNullish(key);
    });
};

/**
 * Saves the grid data state to local forage only if the take or sort
 * descriptor has been modified. This function is used to save the
 * user preferences when the user changes the grid data state.
 * @param {CustomDataState} updatedState - The grid data state that has been
 * modified.
 * @param {CustomDataState} currentState - The current state of the grid data.
 * @param {`${GridNames}`} gridName - The name of the grid.
 * @returns {void}
 */
export const saveDataStateOnUserPreferences = (
    updatedState: CustomDataState,
    currentState: CustomDataState,
    gridName: `${GridNames}`
): void => {
    // save user preferences only if take or sort have been modified
    if (isTakeOrSortModified(updatedState, currentState)) {
        saveOrUpdateUserPreferences(gridName, {
            state: { sort: updatedState.sort, take: updatedState.take },
        });
    }
};

/**
 * Checks if the take or sort descriptor has been modified between the
 * current and the updated state.
 *
 * @param {CustomDataState} updatedState - The grid data state that has been
 * modified.
 * @param {CustomDataState} currentState - The current state of the grid data.
 * @returns {boolean} True if the take or sort has been modified, false otherwise.
 */
const isTakeOrSortModified = (
    updatedState: CustomDataState,
    currentState: CustomDataState
): boolean => {
    return (
        updatedState.take !== currentState.take ||
        JSON.stringify(updatedState.sort) !== JSON.stringify(currentState.sort)
    );
};

/**
 * Checks if all values of user preferences for a given grid name are nullish (null or undefined)
 * @param {`${GridNames}`} gridName - The grid name
 * @returns {boolean} true if all values are nullish, false otherwise
 */

const checkNullishObject = async (gridName: `${GridNames}`): Promise<boolean> => {
    const currentUserPrefences = await getUserPreferences(gridName);
    if (currentUserPrefences) {
        return Object.values(currentUserPrefences).every((val) => !val || val.length === 0);
    }
    return true;
};

/**
 * Function that recursively updates an item nested values by the new provided ones
 * @param {GeneralUserPreferences | UserPreferencesForDashboard} item - The item containing values to be updated
 * @param {GeneralUserPreferences | UserPreferencesForDashboard} newItem -  The item containing new values
 * @returns {GeneralUserPreferences | UserPreferencesForDashboard} The updated item
 */
const updateStorageItem = (
    item: GeneralUserPreferences | UserPreferencesForDashboard,
    newItem: GeneralUserPreferences | UserPreferencesForDashboard
): GeneralUserPreferences | UserPreferencesForDashboard => {
    for (const key in newItem) {
        const itemKey = key as keyof (GeneralUserPreferences | UserPreferencesForDashboard);

        if (newItem[itemKey] !== undefined) {
            if (
                typeof newItem[itemKey] === 'object' &&
                !(newItem[itemKey] instanceof Date) &&
                newItem[itemKey] !== null &&
                !Array.isArray(newItem[itemKey])
            ) {
                if (!item[itemKey]) {
                    item[itemKey] = {} as any;
                }
                updateStorageItem(
                    item[itemKey] as GeneralUserPreferences | UserPreferencesForDashboard,
                    newItem[itemKey] as GeneralUserPreferences | UserPreferencesForDashboard
                );
            } else {
                (item[itemKey] as any) = newItem[itemKey];
            }
        }
    }
    return item;
};
