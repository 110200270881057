import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from '@reduxjs/toolkit';

import { FormikErrors, FormikTouched, FormikValues } from 'formik';

import {
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    SelectChangeEvent,
    SxProps,
    Typography,
    Box,
    ListItemIcon,
    Divider,
} from '@mui/material';

import { SelectDataType } from '../../pages/interfaces';

interface MultipleSelectCheckboxInputProps {
    defaultValue: string[];
    disabled: boolean;
    fieldName: string;
    selectData: SelectDataType[];

    handleChange?: (values: string[]) => void;
    boxStyle?: SxProps;
    className?: string;
    errors?: FormikErrors<FormikValues>;
    placeholder?: string;
    style?: SxProps;
    touched?: FormikTouched<FormikValues>;
}

/**
 * This component renders a multiple-select dropdown with checkboxes for each option.
 * It allows users to select multiple items, and displays the selected items as comma-separated values.
 * @param {SelectDataType[]} props.selectData - Array of objects containing the `value` and `name` of each option.
 * @param {boolean} props.disabled - Controls whether the select input is disabled.
 * @param {string[]} props.defaultValue - Array of strings representing the initially selected values.
 * @param {((values: string[]) => void) | undefined} props.handleChange - Optional function that receives the updated array of selected values.
 * @param {string} props.fieldName - The name of the field in the form values.
 * @param {SxProps} props.style - Custom CSS styling for the multiple select.
 * @param {SxProps} props.boxStyle - Custom CSS styling for the box element.
 * @param {FormikTouched<FormikValues>} [props.touched] - The touched state for the multipleselect component.
 * @param {FormikErrors<FormikValues>} [props.errors] - The errors for the multipleselect component.
 * @param {string} [props.placeholder] - The placeholder for the input.
 * @param {string} [props.className] - An optional class name for the multiple-select component.
 * @returns {JSX.Element} A component that renders a multiple-select dropdown with checkboxes.
 */

const MultipleSelectCheckbox = ({
    boxStyle,
    className,
    defaultValue,
    disabled,
    errors,
    fieldName,
    placeholder,
    selectData,
    style,
    touched,
    handleChange,
}: MultipleSelectCheckboxInputProps): JSX.Element => {
    const { t } = useTranslation();
    const [selectedData, setSelectedData] = useState<string[]>(defaultValue);

    const handleChangeData = (event: SelectChangeEvent<string[]>) => {
        const value = event.target.value;
        const newData = typeof value === 'string' ? value.split(',') : value;
        if (value[value.length - 1] === 'all') {
            const newDataForSelect =
                selectedData.length === selectData.length
                    ? []
                    : selectData.map((item) => item.value);
            setSelectedData(newDataForSelect);
            handleChange?.(newDataForSelect);
            return;
        }
        setSelectedData(newData);
        handleChange?.(newData);
    };

    const getNameForSelectedValue = (selected: string[]) => {
        const values = selected.map(
            (selectedValue) => selectData.filter((data) => data.value === selectedValue)[0]?.name
        );
        return values.join(', ');
    };

    const applyError = !!(touched?.[fieldName] && errors?.[fieldName]);
    const errorMessage = applyError ? errors?.[fieldName] : '';

    const generatePlaceholder = useMemo(() => {
        return <em>{placeholder}</em>;
    }, [placeholder]);

    const selectedCheckbox = selectData.length === selectedData.length;

    return (
        <Box sx={boxStyle}>
            <Select<string[]>
                sx={style}
                multiple
                displayEmpty
                value={selectedData}
                onChange={handleChangeData}
                disabled={disabled}
                className={className}
                renderValue={(selected) =>
                    selected.length === 0 ? generatePlaceholder : getNameForSelectedValue(selected)
                }>
                <MenuItem disabled value="">
                    {generatePlaceholder}
                </MenuItem>
                {selectData.length > 0 && (
                    <MenuItem key={nanoid()} value="all" className="selectAllOption">
                        <ListItemIcon>
                            <Checkbox color="secondary" checked={selectedCheckbox} />
                        </ListItemIcon>
                        <ListItemText primary={t('general.labels.selectAll')} />
                    </MenuItem>
                )}
                {selectData.length > 0 && <Divider sx={{ marginTop: '0px!important' }} />}
                {selectData.map((data) => (
                    <MenuItem key={data.value} value={data.value}>
                        <Checkbox
                            checked={selectedData.includes(data.value)}
                            className="multiple-select-checkbox"
                        />
                        <ListItemText primary={data.name} />
                    </MenuItem>
                ))}
            </Select>
            {errors && (
                <Typography
                    color="#d63232"
                    variant="caption"
                    className="errorHeight"
                    role={`error-${fieldName}`}>
                    <>{errorMessage}</>
                </Typography>
            )}
        </Box>
    );
};

export default MultipleSelectCheckbox;
