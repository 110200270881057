import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps, IconButton, SxProps, Tooltip, TooltipProps } from '@mui/material';

interface TooltipButtonProps {
    title: string | JSX.Element;
    buttonVariant?: ButtonProps['variant'];
    className?: string;
    disabled?: boolean;
    entity?: string;
    icon?: React.ReactNode;
    placement?: TooltipProps['placement'];
    role?: string;
    sx?: SxProps;
    onClick?: () => void;
}

/**
 * Renders a custom button component with a tooltip.
 *
 * @param {TooltipButtonProps} props - The properties for the component containing:
 * @param {string} props.title - The title of the button.
 * @param {string} props.entity - The entity associated with the button.
 * @param {React.ReactNode} props.icon - The icon to display on the button.
 * @param {ButtonProps['variant']} props.buttonVariant - The variant of the button.
 * @param {SxProps} props.sx - Custom CSS styling for the button.
 * @param {() => void} props.onClick - Function to execute on button click.
 * @param {string} props.role - The role of the button.
 * @param {boolean} props.disabled - Flag indicating if the button is disabled.
 * @param {TooltipProps['placement']} props.placement - The placement of the tooltip.
 * @param {string} props.className - Additional CSS classes for the button.
 * @return {JSX.Element} The rendered custom button with tooltip.
 */
const TooltipButton = ({
    title,
    entity,
    icon,
    buttonVariant,
    sx,
    onClick,
    role,
    disabled,
    placement,
    className,
}: TooltipButtonProps): JSX.Element => {
    const { t } = useTranslation();

    const tooltipTitle = useMemo(() => {
        return typeof title === 'string' ? t(title, { entity: t(entity || '') }) : title;
    }, [entity, t, title]);

    return (
        <Tooltip
            className={className}
            sx={sx}
            title={tooltipTitle}
            placement={placement ?? 'bottom'}>
            {buttonVariant ? (
                <span>
                    <Button
                        variant={buttonVariant}
                        onClick={onClick}
                        role={role}
                        disabled={disabled}
                        sx={sx}>
                        {icon}
                    </Button>
                </span>
            ) : (
                <IconButton disableRipple={true} onClick={onClick} role={role}>
                    {icon}
                </IconButton>
            )}
        </Tooltip>
    );
};
export default TooltipButton;
