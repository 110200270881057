import { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';

import { Tabs, Tab, Paper, Grid, Switch, Typography, Box } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { changeModuleStatus, getModuleFields, updateModuleFields } from '../marketplaceDuck';

import { FormComponentFooter, FormComponentHeader } from '../../../common';
import {
    createValidationSchema,
    getServiceStatus,
    handleChangeTabs,
    navigateAfterFulfilledAction,
    navigateToParent,
    preventSubmit,
    setExtendedModuleInfo,
    validateForm,
} from '../../../../helpers';
import { GRID_CLASS_NAME, Operations } from '../../../../constants';
import { Module, ModuleField, ModuleFormValues } from '../../interfaces';
import GeneralDetailsTab from './GeneralDetailsTab';
import ModuleConfigurationTab from './ModuleConfigurationTab';
import {
    updateValidationSchema,
    resetValidationSchema,
} from '../../../common/formControls/formDuck';

/**
 * Renders the details of a module.
 *
 * @return {JSX.Element | null} The rendered module details or null if module is not found.
 */
const ModuleDetails = (): JSX.Element | null => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const pathParams = useParams();
    const { selectedMandator } = useAppSelector((state) => state.mandators);
    const { modules } = useAppSelector((state) => state.marketplace);

    const [moduleStatus, setModuleStatus] = useState<boolean>(false);
    const [module, setModule] = useState<Module>();
    const [moduleFields, setModuleFields] = useState<ModuleField[] | []>([]);
    const [tabState, setTabState] = useState(1);

    const moduleId = pathParams.id;

    const moduleUrl = modules.find((item) => {
        return item.id === moduleId;
    })?._links?.base_url;

    const moduleFieldsUrl = useMemo(() => `${moduleUrl}/config/mandators/fields`, [moduleUrl]);

    useEffect(() => {
        if (moduleId && selectedMandator?.id) {
            setExtendedModuleInfo(moduleId, dispatch, setModule, setModuleStatus);
        }
    }, [dispatch, moduleId, selectedMandator]);

    useEffect(() => {
        if (moduleId && module) {
            setModuleStatus(module.active);
        }
    }, [module, moduleId]);

    const fetchModuleFields = useCallback(async () => {
        if (selectedMandator?.id) {
            const fieldsResponse = await dispatch(getModuleFields(moduleFieldsUrl));
            if (getModuleFields.fulfilled.match(fieldsResponse)) {
                setModuleFields(fieldsResponse.payload.data);
            }
        }
    }, [dispatch, moduleFieldsUrl, selectedMandator]);

    useEffect(() => {
        fetchModuleFields();
    }, [fetchModuleFields]);

    const validationSchema = useMemo(
        () => moduleFields && createValidationSchema(moduleFields),
        [moduleFields]
    );

    const initialValues = useMemo(() => {
        let formInitialValues: ModuleFormValues = {
            active: module?.active ?? false,
        };
        const dynamicModuleFields: { [key: string]: string } = {};
        moduleFields.forEach((field) => {
            dynamicModuleFields[field.name] = field.value;
        });

        if (module) {
            formInitialValues = {
                active: moduleStatus,
                ...(Object.keys(dynamicModuleFields).length > 0 && dynamicModuleFields),
            };
        }

        return formInitialValues;
    }, [module, moduleStatus, moduleFields]);

    useEffect(() => {
        dispatch(updateValidationSchema(validationSchema.fields));
        return () => {
            dispatch(resetValidationSchema());
        };
    }, [dispatch, validationSchema]);

    const updateModuleActivationErrors = (isSuccessfulOperation: boolean) => {
        const moduleName = module?.name ?? '';
        const localStorageActivationErrors = localStorage.moduleActivationErrors
            ? new Map<string, string[]>(JSON.parse(localStorage.moduleActivationErrors))
            : new Map<string, string[]>();
        let currentModules = localStorageActivationErrors.get(localStorage.selectedMandator) ?? [];

        if (isSuccessfulOperation) {
            currentModules = currentModules.filter((mod) => mod !== moduleName);
        } else {
            if (!currentModules.includes(moduleName)) {
                currentModules = [...currentModules, moduleName];
            }
        }
        localStorageActivationErrors.set(localStorage.selectedMandator, currentModules);
        localStorage.setItem(
            'moduleActivationErrors',
            JSON.stringify(Array.from(localStorageActivationErrors))
        );
    };

    const saveModuleFields = async (formFields: ModuleFormValues) => {
        // if we have other fields on module
        // save them
        if (Object.keys(formFields).length > 1) {
            const { active, ...fieldValues } = formFields;
            await dispatch(
                updateModuleFields({
                    moduleUrl: moduleFieldsUrl,
                    moduleFields: fieldValues,
                })
            );
        }
    };

    const handleSubmit = async (fields: ModuleFormValues) => {
        if (selectedMandator?.id) {
            if (fields.active) {
                await saveModuleFields(fields);
            }
            if (fields.active !== undefined) {
                const changeModuleStatusResponse = await dispatch(
                    changeModuleStatus({
                        moduleId: moduleId ?? '',
                        active: fields.active,
                    })
                );
                if (changeModuleStatus.fulfilled.match(changeModuleStatusResponse)) {
                    updateModuleActivationErrors(true);
                    navigateAfterFulfilledAction(navigate, Operations.Updated);
                } else {
                    updateModuleActivationErrors(false);
                    // navigate to parent
                    navigateToParent(navigate);
                }
            }
            getServiceStatus(moduleId ?? '', dispatch);
        }
    };

    return (
        <>
            {module ? (
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={(fields) => {
                        handleSubmit(fields);
                    }}
                    validationSchema={validationSchema}
                    validate={(fields) => validateForm(fields)}>
                    {(props) => (
                        <>
                            <FormComponentHeader
                                title={module.name}
                                customButton={
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                        <Typography variant="h3">
                                            {t('general.labels.active')}
                                        </Typography>
                                        <Switch
                                            color="success"
                                            checked={props.values.active}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            name="active"
                                        />
                                    </Box>
                                }
                            />
                            <Paper
                                elevation={0}
                                sx={{ mt: 2, backgroundColor: '#fff', width: '100%' }}>
                                <Form onKeyDown={preventSubmit}>
                                    <Tabs
                                        value={tabState}
                                        onChange={(_, value: number) => {
                                            handleChangeTabs(setTabState, value);
                                        }}
                                        scrollButtons="auto">
                                        <Tab
                                            value={1}
                                            label={t('general.labels.generalTab')}
                                            wrapped
                                        />

                                        {moduleFields.length > 0 && (
                                            <Tab
                                                value={2}
                                                label={t('general.labels.configurationTab')}
                                                wrapped
                                            />
                                        )}
                                    </Tabs>

                                    <Grid
                                        container
                                        columnSpacing={{ xs: 2, sm: 2, md: 4, lg: 8 }}
                                        spacing={2}
                                        className={GRID_CLASS_NAME}>
                                        {tabState === 1 && <GeneralDetailsTab module={module} />}
                                        {tabState === 2 && (
                                            <ModuleConfigurationTab
                                                moduleFields={moduleFields}
                                                formProps={props}
                                                selectedMandatorId={selectedMandator?.id ?? ''}
                                                componentName={module.name}
                                                isDisabled={!props.values.active}
                                            />
                                        )}
                                    </Grid>
                                    <FormComponentFooter isDisabled={false} />
                                </Form>
                            </Paper>
                        </>
                    )}
                </Formik>
            ) : null}
        </>
    );
};

export default ModuleDetails;
