import { FocusEventHandler, memo } from 'react';

// UI library
import { GridCellProps } from '@progress/kendo-react-grid';

//helpers
import { processHoursValue, processMinutesValue } from '../../../../helpers';

interface NumericalTimeInputCellProps extends GridCellProps {
    disabled?: boolean;
    field?: string;
    onBlur?: FocusEventHandler<HTMLInputElement>;
}

/**
 * Renders a custom cell component for a numerical time input in a grid.
 * Used for grids with built in edit functionality
 *
 * @param {NumericalTimeInputCellProps} props - The props for the component.
 * @param {string} props.field - The field name for the input.
 * @param {Function} props.onChange - The event handler for onChange.
 * @param {Function} props.onBlur - The event handler for onBlur.
 * @param {boolean} props.disabled - Boolean indicating if the input is disabled.
 * @param {object} props.dataItem - The data item for the current row.
 * @param {number} props.dataIndex - The index of the current row.
 * @return {JSX.Element} The rendered custom cell component.
 */
const NumericalTimeInputCell = ({
    dataIndex,
    dataItem, //hh:mm or hh:mm:ss format
    disabled,
    field,
    onBlur,
    onChange,
}: NumericalTimeInputCellProps): JSX.Element => {
    const numberRegex = /^\d*$/;
    const fieldName = field ?? '';
    const handleHoursChange = (event: any) => {
        const eventValue = event.target.value;
        if (!numberRegex.test(eventValue)) {
            return;
        }

        const processedHour = processHoursValue(eventValue);
        if (processedHour === '24') {
            event.target.value = '24:00';
        } else {
            event.target.value = `${processedHour}${dataItem[fieldName].slice(2)}`;
        }

        if (onChange) {
            onChange({
                dataItem,
                dataIndex,
                syntheticEvent: event.syntheticEvent,
                field: fieldName,
                value: event.target.value,
            });
        }
    };

    const handleMinutesChange = (event: any) => {
        const eventValue = event.target.value;
        if (dataItem[fieldName].startsWith('24') || !numberRegex.test(eventValue)) {
            return;
        }

        event.target.value = `${dataItem[fieldName].slice(0, 2)}:${processMinutesValue(
            eventValue
        )}`;

        if (onChange) {
            onChange({
                dataItem,
                dataIndex,
                syntheticEvent: event.syntheticEvent,
                field: fieldName,
                value: event.target.value,
            });
        }
    };

    return (
        <td className="formInputContainer" aria-label="timeInput">
            <div className="inputsContainer cell">
                <input
                    name={fieldName}
                    value={dataItem[fieldName] ? dataItem[fieldName].slice(0, 2) : '00'}
                    disabled={disabled}
                    placeholder="hh"
                    type="text"
                    aria-label="hours-input"
                    className="numericalTimeInput hours"
                    onChange={handleHoursChange}
                    onFocus={(event) => event.target.select()}
                    onBlur={onBlur}
                />
                <span style={{ height: '20px' }}>:</span>
                <input
                    name={fieldName}
                    value={dataItem[fieldName] ? dataItem[fieldName].slice(3, 5) : '00'}
                    disabled={disabled}
                    placeholder="mm"
                    type="text"
                    aria-label="minutes-input"
                    className="numericalTimeInput minutes"
                    onChange={handleMinutesChange}
                    onFocus={(event) => event.target.select()}
                    onBlur={onBlur}
                />
            </div>
        </td>
    );
};

export default memo(NumericalTimeInputCell);
