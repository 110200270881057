import { RefObject, useEffect } from 'react';
import { FormikProps } from 'formik';
import { exitEdit } from '../../helpers';

/**
 * A custom hook that handles exiting edit mode for a form field when a specified
 * element is clicked. It updates the field's value and optionally triggers a custom
 * exit edit function.
 *
 * @template T - The type of the form values.
 *
 * @param {RefObject<FormikProps<T>>} formRef - A reference to the Formik form instance.
 * @param {string} field - The name of the field to update on exit.
 * @param {string} className - The CSS class name to look for in the click event target.
 * @param {Function} [customExitEdit] - An optional custom function to call on exit.
 *
 */
export const useExitEdit = <T extends Record<any, any>>(
    formRef: RefObject<FormikProps<T>>,
    field: string,
    className: string,
    customExitEdit?: () => void
) => {
    useEffect(() => {
        function editRowEvent(event: MouseEvent) {
            const target = event.target as HTMLElement;
            if (target.classList.contains(className)) {
                formRef.current?.setFieldValue(field, exitEdit(formRef.current?.values[field]));
                customExitEdit?.();
            }
        }
        window.addEventListener('click', editRowEvent);
        return () => window.removeEventListener('click', editRowEvent);
    }, [className, customExitEdit, field, formRef]);
};
