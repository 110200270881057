import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, SelectChangeEvent } from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import * as Yup from 'yup';

import { useAppDispatch, useAppSelector, useDownloadFile } from '../../hooks';

import { FormInput, Interceptor, RadioButtonGroup, SelectComponent } from '..';
import { FileExtension } from '../../pages/interfaces';
import { getFileName, showToaster } from '../../../helpers';
import { Resources, ToasterType } from '../../../constants';
import { ControlRadioButton } from '../interfaces';
import { getReportTemplateById, getReportTemplates } from '../../pages/reports/reportsDuck';

interface ReportTemplateData {
    name: string;
    value: string;
}

interface DownloadModalProps {
    formInitialValues: FormikValues;
    mandatorName: string;
    selectOptions: ControlRadioButton[];
    handleModalClose(): void;

    formValidations?: Yup.ObjectSchema<FormikValues>;
    getData?: (fields: FormikValues | string) => void | Promise<any>;
}

/**
 * Renders a download modal component.
 *
 * @param {DownloadModalProps} props - The props for the component.
 * @param {ControlRadioButton[]} props.selectOptions - The options for the select component.
 * @param {FormikValues} props.formInitialValues - The initial values for the form.
 * @param {string} props.mandatorName - The name of the mandator.
 * @param {() => void} props.handleModalClose - The function to close the modal.
 * @param {((fields: FormikValues | string) => void | Promise<any>)?} props.getData - The function to get data.
 * @param {Yup.ObjectSchema<FormikValues>?} props.formValidations - The validation schema for the form.
 * @return {JSX.Element} The rendered download modal component.
 */
const DownloadModal = ({
    formInitialValues,
    formValidations,
    mandatorName,
    selectOptions,
    getData,
    handleModalClose,
}: DownloadModalProps): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { formats } = useAppSelector((state) => state.commonData);
    const { reportTemplates, reportTemplate } = useAppSelector((state) => state.reports);
    const [selectedExportFormat, setSelectedExportFormat] = useState<`${FileExtension}`>(
        FileExtension.CSV
    );
    const [templateId, setTemplateId] = useState<string>('');
    const [templateEmails, setTemplateEmails] = useState<string[]>([]);
    const [selectedEmails, setSelectedEmails] = useState<string[]>([]);

    const reportTemplatesData: ReportTemplateData[] = reportTemplates.data.map((template) => ({
        name: template.name,
        value: template.id,
    }));

    const getReportsData = useCallback(() => {
        dispatch(
            getReportTemplates({
                resource_name: Resources.Transactions,
            })
        );
    }, [dispatch]);

    useEffect(() => {
        getReportsData();
    }, [getReportsData]);

    useEffect(() => {
        if (templateId) {
            dispatch(getReportTemplateById(templateId));
        }
    }, [dispatch, templateId]);

    useEffect(() => {
        if (reportTemplate && templateId) {
            setTemplateEmails(reportTemplate.emails);
            setSelectedEmails(reportTemplate.emails);
        }
    }, [dispatch, reportTemplate, templateId]);

    const handleSubmit = async (fields: FormikValues) => {
        handleModalClose();
        if (
            (fields.option !== 'report' && (!fields.emails || fields.emails.length === 0)) ||
            (fields.option === 'report' && selectedEmails.length === 0) ||
            selectedEmails.length === 0
        ) {
            download();
        } else {
            fields.emails = selectedEmails;
            await getData?.(fields);
            showToaster(ToasterType.Success, t('general.messages.successfulSentEmail'));
        }
    };

    /**
     * Resets the selected emails and template id when the export format is changed.
     *
     * @param {string} type The new export format.
     */
    const changeExportFormat = (type: string) => {
        setSelectedEmails([]);
        setTemplateEmails([]);
        setTemplateId('');
        switch (type) {
            case FileExtension.XLSX:
                setSelectedExportFormat('xlsx');
                break;
            case FileExtension.REPORT:
                setSelectedExportFormat('report');
                break;
            default:
                setSelectedExportFormat('csv');
        }
    };

    const getFile = useCallback(async () => {
        const response = await getData?.({
            option: selectedExportFormat !== 'report' ? selectedExportFormat : undefined,
            templateId,
        });
        // get file content
        return Interceptor().get(response, { responseType: 'blob' });
    }, [getData, selectedExportFormat, templateId]);

    const onErrorDownloadFile = () => {
        showToaster(ToasterType.Error, t('general.messages.downloadFailed'));
    };
    const { download } = useDownloadFile({
        apiDefinition: getFile,
        onError: onErrorDownloadFile,
        fileName: getFileName(formats.momentDateTimeFormat, selectedExportFormat, mandatorName),
    });

    return (
        <Formik
            initialValues={formInitialValues}
            onSubmit={handleSubmit}
            validationSchema={formValidations}>
            {({ values, handleChange, touched, errors, setFieldValue }) => (
                <Form
                    style={{
                        minHeight: '360px',
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '16px',
                        alignContent: 'center',
                        flexWrap: 'wrap',
                    }}>
                    <RadioButtonGroup
                        labelVariant="h3"
                        color="secondary"
                        label={t('reports.exportAs')}
                        controls={selectOptions}
                        value={selectedExportFormat}
                        handleChange={(event) => {
                            changeExportFormat(event.target.value);
                            setFieldValue('option', event.target.value);
                            if (event.target.value !== 'report') {
                                setFieldValue('templateId', '');
                            }
                        }}
                    />
                    {selectedExportFormat === 'report' && (
                        <SelectComponent
                            data={reportTemplatesData}
                            defaultValue={values.templateId}
                            onChange={(e: SelectChangeEvent<unknown>) => {
                                setTemplateId(e.target.value as string);
                                handleChange(e);
                            }}
                            valueEntry="value"
                            titleEntry="name"
                            name={'templateId'}
                            label={t('general.labels.template')}
                            className="formControl"
                            formChild
                            touched={touched}
                            infoLabel={t('general.messages.selectReportTemplateInfo')}
                        />
                    )}
                    <FormInput
                        sx={{ width: '400px' }}
                        type="autoCompleteChip"
                        label={t('general.labels.emailTo')}
                        fieldName="emails"
                        errors={errors}
                        touched={touched}
                        onChangeAutocompleteChipValue={(e) => {
                            setFieldValue('emails', e);
                            setSelectedEmails(e);
                        }}
                        autoCompleteOptions={templateEmails ?? undefined}
                        autoCompleteValues={selectedEmails ?? undefined}
                        infoButtonLabel={t('general.messages.emailToInfo')}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 'auto' }}>
                        <Button
                            variant="outlined"
                            onClick={handleModalClose}
                            sx={{
                                mr: 1,
                            }}>
                            {t('general.labels.cancel')}
                        </Button>

                        <Button type="submit" variant="contained">
                            {t('general.labels.export')}
                        </Button>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default DownloadModal;
