import { ComponentType } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { MIN_COLUMN_WIDTH, COLUMN_VISIBLE } from '../../../constants';
import { IGridColumn } from '../../common/interfaces';

/**
 * Returns grid columns for prices page.
 *
 * @returns {IGridColumn[]} - Array of IGridColumn objects.
 */
export const getPricesColumns = (): IGridColumn[] => {
    return [
        {
            field: 'name',
            title: 'sites.entityName',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'last_update_of_prices',
            title: 'prices.lastUpdated',
            filter: 'date',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
    ];
};

/**
 * Returns grid columns for site prices page.
 *
 * @param nowColumnVisibility - Shows 'now' column in grid, defaults to false.
 * @param customCells - A map of custom cell components, defaults to undefined.
 * @returns {IGridColumn[]} - Array of IGridColumn objects.
 */
export const getSitePricesColumns = (
    nowColumnVisibility?: boolean,
    customCells?: Map<string, ComponentType<GridCellProps>>
): IGridColumn[] => {
    return [
        {
            field: 'product_name',
            title: 'prices.productName',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            editable: false,
        },
        {
            field: 'unit_price',
            title: 'prices.unitPrice',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            editable: false,
        },
        {
            field: 'new_unit_price',
            title: 'prices.newUnitPrice',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            cell: customCells?.get('newUnitPrice'),
        },
        nowColumnVisibility
            ? {
                  field: 'now',
                  title: 'prices.now',
                  minWidth: MIN_COLUMN_WIDTH,
                  width: MIN_COLUMN_WIDTH,
                  sortable: false,
                  show: COLUMN_VISIBLE,
                  cell: customCells?.get('now'),
              }
            : {},
        {
            field: 'activation_timestamp',
            title: 'prices.activationDateAndTime',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            cell: customCells?.get('activationDateAndTime'),
        },
    ];
};

/**
 * Returns an array of IGridColumn objects for the Relations table.
 * @param {Map<string, ComponentType<GridCellProps>>} customCells - A map of custom cell components.
 * @returns {IGridColumn[]} - An array of IGridColumn objects.
 */
export const getRelationsColumns = (
    customCells: Map<string, ComponentType<GridCellProps>>
): IGridColumn[] => {
    return [
        {
            field: 'product_name',
            title: 'prices.productName',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            editable: false,
        },
        {
            field: 'related_products',
            title: 'prices.assignedProducts',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            cell: customCells?.get('assignedProducts'),
        },
    ];
};

export const getIntervalsColumns = (
    customCells: Map<string, ComponentType<GridCellProps>>
): IGridColumn[] => {
    return [
        {
            field: 'product_name',
            title: 'prices.productName',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            editable: false,
            cell: customCells?.get('assignIntervals'),
            isHiddenColumnMenu: true,
        },
    ];
};

export const getProductIntervalsColumns = (
    customCells: Map<string, ComponentType<GridCellProps>>
): IGridColumn[] => {
    return [
        {
            field: 'price',
            title: 'prices.staticPrice',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            cell: customCells?.get('price'),
        },
        {
            field: 'start_time',
            title: 'devices.remoteControl.start',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            cell: customCells?.get('start_time'),
        },
        {
            field: 'end_time',
            title: 'receipts.articles.end_time',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            cell: customCells?.get('end_time'),
        },
        {
            field: 'monday',
            title: 'general.dates.monday',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            cell: customCells?.get('monday'),
        },
        {
            field: 'tuesday',
            title: 'general.dates.tuesday',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            cell: customCells?.get('tuesday'),
        },
        {
            field: 'wednesday',
            title: 'general.dates.wednesday',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            cell: customCells?.get('wednesday'),
        },
        {
            field: 'thursday',
            title: 'general.dates.thursday',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            cell: customCells?.get('thursday'),
        },
        {
            field: 'friday',
            title: 'general.dates.friday',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            cell: customCells?.get('friday'),
        },
        {
            field: 'saturday',
            title: 'general.dates.saturday',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            cell: customCells?.get('saturday'),
        },
        {
            field: 'sunday',
            title: 'general.dates.sunday',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            cell: customCells?.get('sunday'),
        },
    ];
};
