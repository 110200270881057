import { GridCellProps, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { ComponentType, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

//redux
import { useAppDispatch, useAppSelector } from '../../../hooks';

// common components, interfaces, constants and helpers
import { Typography } from '@mui/material';
import { EDIT_ACTION_BUTTONS } from '../../../../constants/kendoConstants';
import { getInitialGridState, updateGridColumns } from '../../../../helpers';
import {
    ActionButtonsCell,
    ComponentHeader,
    GridActionsAndFilters,
    GridComponent,
    TextCell,
} from '../../../common';
import { CustomDataState, IGridColumn } from '../../../common/interfaces';
import { ExtendedGetReqProps } from '../../../pages/interfaces';
import { getSites } from '../../../pages/sites/siteDuck';
import { getFleetnetSiteColumns } from '../../gridColumn/fleetnetSite';
import ExpandFleetnetSitesComponent from './ExpandFleetnetSitesComponent';

interface FleetnetSiteCustomCellProps extends GridCellProps {
    buttonsVisibility: Map<string, boolean>;
}

const FleetnetSitesList = () => {
    const fleetSiteButtonsVisibility = useMemo(() => new Map(EDIT_ACTION_BUTTONS), []);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { selectedMandator } = useAppSelector((state) => state.mandators);
    const { sites } = useAppSelector((state) => state.sites);
    const [columns, setColumns] = useState<IGridColumn[]>([]);
    const [sitesServerDataState, setSitesServerDataState] = useState<CustomDataState>(
        getInitialGridState('name')
    );

    const getSitesData = useCallback(
        (mandatorId: string, serverDataState: CustomDataState) => {
            const requestObj: ExtendedGetReqProps = {
                data_state: serverDataState,
                mandator_id: mandatorId,
            };

            dispatch(getSites(requestObj));
        },
        [dispatch]
    );

    // get sites for selected mandator
    useEffect(() => {
        if (selectedMandator?.id) {
            getSitesData(selectedMandator.id, sitesServerDataState);
        }
    }, [sitesServerDataState, getSitesData, selectedMandator]);

    const CustomCellForName = (props: FleetnetSiteCustomCellProps) => {
        return (
            <TextCell
                {...props}
                component={
                    <ActionButtonsCell
                        {...props}
                        path={`/fleetnet-sites/${props?.dataItem?.id}`}
                        index="id"
                        buttonsVisibility={props.buttonsVisibility}
                        component="services.manageFleets.fleets"
                    />
                }
            />
        );
    };

    const NameCell = useCallback(
        (props: GridCellProps) => {
            return <CustomCellForName {...props} buttonsVisibility={fleetSiteButtonsVisibility} />;
        },
        [fleetSiteButtonsVisibility]
    );

    const CustomColumns = useMemo(
        () => new Map<string, ComponentType<GridCellProps>>([['name', NameCell]]),
        [NameCell]
    );

    useEffect(() => {
        const initialColumns = getFleetnetSiteColumns(CustomColumns);
        if (columns.length === 0) {
            setColumns(initialColumns);
        } else {
            updateGridColumns(columns, initialColumns, setColumns);
        }
    }, [CustomColumns]);

    const onSitesDataStateChange = useCallback(
        (event: GridDataStateChangeEvent) => {
            const { filter, group, ...state } = event.dataState;
            setSitesServerDataState({ ...sitesServerDataState, ...state });
        },
        [sitesServerDataState]
    );

    //I18n
    const emptyListMsg = useMemo(() => t(`fleetnetSite.noSiteFound`), []);

    //If empty then display this component
    const NoFleetnetSitesComponent = useMemo(
        () => (
            <Typography
                variant="subtitle1"
                align="center"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}>
                {emptyListMsg}
            </Typography>
        ),
        [emptyListMsg]
    );

    const formattedSites = {
        data: sites.data.map((site) => ({ ...site })),
        total: sites.total,
    };

    return (
        <>
            <ComponentHeader title={t('fleetnetSite.fleetnetSitesHeader')} />
            <GridActionsAndFilters
                viewRight={false}
                entityName="services.manageSites.sites"
                columns={columns}
                componentServerDataState={sitesServerDataState}
                setComponentServerDataState={setSitesServerDataState}
            />
            <GridComponent
                result={formattedSites}
                columns={columns}
                initialColumns={getFleetnetSiteColumns(CustomColumns)}
                setColumns={setColumns}
                serverDataState={sitesServerDataState}
                detailComponent={ExpandFleetnetSitesComponent}
                onServerDataStateChange={onSitesDataStateChange}
                noDataMessage={NoFleetnetSitesComponent}
                className="fleet-sites-grid"
            />
        </>
    );
};

export default FleetnetSitesList;
