import { ImageMetaData } from '../app/common/interfaces';

// default files validations
export const IMAGE_EXTENSIONS = ['.jpg', '.jpeg', '.png'];
export const TEXT_FILE_EXTENSIONS = ['.txt', '.docx', '.doc', '.csv', '.xls', '.xlsx'];
export const MAX_IMAGE_SIZE = 5000000; // bytes in binary - 5MB
export const MAX_FILE_SIZE = 10000000; // bytes in binary - 10MB
export const PEM_FILE_EXTENSION = ['.pem'];

// default values defined for each image - for the moment these are used for digital receipt template and customization
const DEFAULT_LOGO_METADATA = { label: 'logo', maxSize: MAX_IMAGE_SIZE };

export const COMPANY_LOGO_METADATA: ImageMetaData = {
    width: 130,
    height: 40,
    ...DEFAULT_LOGO_METADATA,
};

export const LOGO_METADATA: ImageMetaData = {
    width: 150,
    height: 150,
    ...DEFAULT_LOGO_METADATA,
};
export const TWINT_LOGO_METADATA: ImageMetaData = {
    width: 113,
    height: 74,
    ...DEFAULT_LOGO_METADATA,
};

export const EMAIL_SIGNATURE_METADATA: ImageMetaData = {
    width: 140,
    height: 50,
    ...DEFAULT_LOGO_METADATA,
};

export const PROMO_METADATA: ImageMetaData = {
    width: 600,
    height: 160,
    label: 'promo',
    maxSize: MAX_IMAGE_SIZE,
};

export const QR_METADATA: ImageMetaData = {
    width: 160,
    height: 160,
    label: 'qr',
    maxSize: MAX_IMAGE_SIZE,
};
