import { EditorTools } from '@progress/kendo-react-editor';
import { GridHierarchyCell } from '@progress/kendo-react-grid';
const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
} = EditorTools;

export const SELECTED_FIELD = 'selected';
export const EXPANDED_FIELD = 'expanded';
export const EDIT_FIELD = 'inEdit';

export const MIN_COLUMN_WIDTH = 100;
export const CUSTOM_COLUMN_MIN_WIDTH = 200;
export const COLUMN_VISIBLE = true;

export const INITIAL_GRID_STATE = {
    take: 20,
    skip: 0,
};

export const LARGER_INITIAL_GRID_STATE = {
    take: 100,
    skip: 0,
};

export const CUSTOM_INITIAL_GRID_STATE = {
    ...INITIAL_GRID_STATE,
    page: 1,
    sort: [],
};
export const SELECTION_COLUMN = { field: SELECTED_FIELD, width: 50, show: COLUMN_VISIBLE };
export const EXPAND_COLUMN = {
    field: EXPANDED_FIELD,
    title: ' ',
    width: 32,
    show: COLUMN_VISIBLE,
    sortable: false,
    isHiddenColumnMenu: true,
    cell: GridHierarchyCell,
};
export const GRID_PADDING = 11;
export const EXPAND_COLUMN_WIDTH = 32;

// default action buttons visibility map
export const ACTION_BUTTONS_VISIBILITY = new Map<string, boolean>([
    ['view', true],
    ['edit', true],
    ['delete', true],
]);
export const ACTION_BUTTONS_DYNAMIC_GRIDS = new Map<string, boolean>([
    ['edit', true],
    ['delete', true],
]);
export const EDIT_ACTION_BUTTONS = new Map<string, boolean>([['edit', true]]);
export const FILE_ACTION_BUTTONS = new Map<string, boolean>([['receipt', true]]);
export const ADD_NEW_ROW = new Map<string, boolean>([['addRow', true]]);

export enum SortDirection {
    Descending = 'desc',
    Ascending = 'asc',
}

// default kendo editor configuration
export const BASE_EDITOR_TOOLS = [
    [Bold, Italic, Underline],
    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
    [OrderedList, UnorderedList],
    FontSize,
    FontName,
    [Undo, Redo],
];

// digital receipt editor configuration
export const EXTENDED_EDITOR_TOOLS = [
    [Bold, Italic, Underline, Strikethrough],
    [Subscript, Superscript],
    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
    [Indent, Outdent],
    [OrderedList, UnorderedList],
    FontSize,
    FontName,
    FormatBlock,
    [Undo, Redo],
    [Link, Unlink],
];
