export enum GridNames {
    //live data grids
    LiveTransactions = 'liveTransactions',
    Transactions = 'transactions',
    Reports = 'reports',
    //administration grids
    Users = 'users',
    Mandators = 'mandators',
    //configuration grids
    ProductsAssignments = 'productAssignments',
    Sites = 'sites',
    Devices = 'devices',
    Points = 'points',
    Prices = 'prices',
    DigitalReceipts = 'digitalReceipts',
    DRSitesAssignments = 'digitalReceiptSites',
    //control tower grids
    UserPermissions = 'userPermissions',
    ChargingStations = 'chargingStations',
    ProviderTemplates = 'providerTemplates',
    ProviderFields = 'providerFields',
    Products = 'products',
    //payments grids
    Cards = 'cards',
    TWINT = 'twint',
}
