import { useState, useEffect, useMemo, useCallback, Suspense } from 'react';
import { Box, Toolbar } from '@mui/material';

// redux
import {
    useActiveModules,
    useAppDispatch,
    useAppSelector,
    usePayedServices,
    useRemoteControlActions,
    useWindowSize,
} from '../../hooks';
import { displayModal } from '../modal/modalDuck';
import {
    getMandatorById,
    getMandatorsList,
    updateSelectedMandator,
} from '../../pages/mandators/mandatorDuck';
import { updateSideWidth } from './layoutDuck';
import { getServices } from '../../pages/services/servicesDuck';

// common components, interfaces, constants and helpers
import Header from './header/Header';
import SideBar from './SideBar';
import DialogComponent from '../modal/DialogComponent';
import Loading from '../loading/Loading';

import {
    BREAKPOINTS,
    PAYED_SERVICES_KEY,
    SIDEBAR_WIDTH_MAX,
    SIDEBAR_WIDTH_MIN,
} from '../../../constants';
import { updateSelectedTheme } from '../../../helpers';
import { ModulePermissions } from '../interfaces';
import { getModules } from '../../pages/marketplace/marketplaceDuck';

interface DefaultLayoutProps {
    component: JSX.Element;
    userRight?: string;
    payed?: boolean;
}
enum variantValues {
    permanent = 'permanent',
    temporary = 'temporary',
}

/**
 * Renders the default layout for the application.
 *
 * @param {DefaultLayoutProps} props - The properties for the default layout.
 * @param {JSX.Element} props.component - The component to render.
 * @param {string} [props.userRight] - The user's right.
 * @param {boolean} [props.payed] - Indicates if the component is payed.
 * @return {JSX.Element} The rendered default layout.
 */
const DefaultLayout = ({ component, userRight, payed }: DefaultLayoutProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const { userRights } = useAppSelector((state) => state.userRights);
    const { payedComponents } = useAppSelector((state) => state.payedServices);
    const { accessToken } = useAppSelector((state) => state.auth);
    const { userMandatorsList, selectedMandator } = useAppSelector((state) => state.mandators);
    const { sideWidth } = useAppSelector((state) => state.layout);
    const { activeModules } = useAppSelector((state) => state.marketplace);
    const { loading } = useAppSelector((state) => state.loading);
    const windowDimensions = useWindowSize();
    const [open, setOpen] = useState(true);
    const [variant, setVariant] = useState(variantValues.permanent);

    // update payed services and components maps
    usePayedServices(PAYED_SERVICES_KEY);
    usePayedServices();
    useActiveModules();

    useRemoteControlActions();

    const isTabletSize = useMemo(
        () => windowDimensions.width > BREAKPOINTS.xs && windowDimensions.width < BREAKPOINTS.md,
        [windowDimensions]
    );
    const isMobileSize = useMemo(
        () => windowDimensions.width <= BREAKPOINTS.xs,
        [windowDimensions]
    );

    const getModulesData = useCallback(() => {
        dispatch(getModules());
    }, [dispatch, selectedMandator]);

    useEffect(() => {
        if (localStorage.selectedMandator) {
            getModulesData();
        }
    }, [localStorage.selectedMandator, getModulesData]);

    useEffect(() => {
        isTabletSize
            ? dispatch(updateSideWidth(SIDEBAR_WIDTH_MIN))
            : dispatch(updateSideWidth(SIDEBAR_WIDTH_MAX));
        isTabletSize || isMobileSize ? setOpen(false) : setOpen(true);
    }, [isTabletSize, isMobileSize, dispatch]);

    useEffect(() => {
        //get mandators and services
        if (accessToken && userMandatorsList.length === 0) {
            dispatch(getServices());
            dispatch(getMandatorsList());
        }
    }, [dispatch, accessToken, userMandatorsList]);

    //get selected mandator services
    const getSelectedMandator = useCallback(async () => {
        if (localStorage.selectedMandator) {
            const res = await dispatch(getMandatorById(localStorage.selectedMandator));
            const selectedMandatorData = res.payload;
            if (selectedMandatorData) {
                dispatch(updateSelectedMandator(selectedMandatorData));
                updateSelectedTheme(selectedMandatorData, dispatch);
            }
        }
    }, [dispatch]);

    useEffect(() => {
        getSelectedMandator();
    }, [getSelectedMandator]);

    const handleDrawerOpen = () => {
        setVariant(variantValues.permanent);
        setOpen(!open);

        !open
            ? dispatch(updateSideWidth(SIDEBAR_WIDTH_MAX))
            : dispatch(updateSideWidth(SIDEBAR_WIDTH_MIN));
    };

    /**
     * Handles the mouse enter event for the sidebar.
     * If the sidebar width is at the minimum and it is not open, it will update the sidebar width to the maximum,
     * set the variant to temporary, and toggle the open state.
     * If the sidebar width is at the minimum and it is open, it will update the sidebar width to the minimum.
     */
    const handleMouseEnter = () => {
        if (sideWidth === SIDEBAR_WIDTH_MIN) {
            if (!open) {
                dispatch(updateSideWidth(SIDEBAR_WIDTH_MAX));
                setVariant(variantValues.temporary);
                setOpen(!open);
            } else {
                dispatch(updateSideWidth(SIDEBAR_WIDTH_MIN));
            }
        }
    };

    /**
     * Handles the mouse leave event for the sidebar.
     *
     * @return {void} No return value.
     */
    const handleMouseLeave = (): void => {
        if (sideWidth === SIDEBAR_WIDTH_MAX) {
            if (!open) {
                dispatch(updateSideWidth(SIDEBAR_WIDTH_MIN));
            } else if (open && variant === variantValues.temporary) {
                dispatch(updateSideWidth(SIDEBAR_WIDTH_MIN));
                setVariant(variantValues.permanent);
                setOpen(!open);
            } else {
                dispatch(updateSideWidth(SIDEBAR_WIDTH_MAX));
                setVariant(variantValues.permanent);
            }
        }
    };

    /**
     * Checks if there are pending axios requests and closes the modal if there are none.
     *
     * @return {void} No return value
     */
    const handleModalClose = (): void => {
        // check if we have pending axios requests
        if (!loading) {
            setVariant(variantValues.permanent);
            dispatch(
                displayModal({
                    showModal: false,
                })
            );
        } else return;
    };

    const Component = useMemo(() => {
        if (userRights.length > 0) {
            const rightData = userRights.filter(
                (el: ModulePermissions) => el.sub_component === userRight
            )[0];

            const viewComponent = userRight ? rightData.view : true;
            const payedComponent = payed ? payedComponents.get(userRight ?? '') : true;
            const isPageActivated = rightData?.service_id
                ? activeModules.includes(rightData.service_id ?? '')
                : true;
            return viewComponent && payedComponent && isPageActivated && component;
        }
    }, [activeModules, component, payed, payedComponents, userRight, userRights]);

    return (
        <Box sx={{ display: 'flex' }}>
            <Header handleDrawerOpen={handleDrawerOpen} width={windowDimensions.width} />
            {windowDimensions.width > BREAKPOINTS.xs ? (
                <SideBar
                    variant={variant}
                    mobileOpen={open}
                    width={sideWidth}
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                />
            ) : (
                <SideBar
                    variant={variantValues.temporary}
                    mobileOpen={open}
                    width={sideWidth}
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                    handleDrawerOpen={handleDrawerOpen}
                />
            )}
            <Box sx={{ flexGrow: 1, p: 3, overflowX: 'auto' }} component="main">
                <Toolbar />
                {localStorage.selectedModule && (
                    <Suspense fallback={<Loading />}>{Component}</Suspense>
                )}
            </Box>
            <DialogComponent handleClose={handleModalClose} />
            <Loading />
        </Box>
    );
};
export default DefaultLayout;
