import { useState } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import moment from 'moment';
import { TimePicker, TimePickerChangeEvent } from '@progress/kendo-react-dateinputs';

import { TIME_FORMAT } from '../../../../constants';

interface TimePickerCellProps extends GridCellProps {
    minTime?: Date;
    disabled?: boolean;
}

/**
 * A cell component for  Grid that allows users to select a time
 * using a TimePicker. It displays the time in a formatted string when not in edit mode.
 *
 * @param {TimePickerCellProps} props - The properties for the TimePickerCell component.
 * @param {Object} props.dataItem - The data item representing the row of the grid.
 * @param {number} props.dataIndex - The index of the data item in the grid.
 * @param {string} [props.className] - Additional CSS classes for the cell.
 * @param {Function} [props.render] - Custom render function for the cell.
 * @param {string} [props.field] - The field name in the data item for binding.
 * @param {boolean} [props.disabled] - Whether the TimePicker is disabled.
 * @param {Date} [props.minTime] - The minimum selectable time.
 * @param {Function} [props.onChange] - Callback function to handle value changes.
 *
 * @returns {JSX.Element} The rendered TimePickerCell component.
 */
const TimePickerCell = (props: TimePickerCellProps): JSX.Element | null => {
    const { dataItem, dataIndex, className, render, field, disabled, minTime, onChange } = props;
    const fieldName = field ?? '';

    const [inputValue, setInputValue] = useState(dataItem[field ?? '']);

    const handleChange = (e: TimePickerChangeEvent) => {
        const newValue = e.target.value;
        if (onChange) {
            onChange({
                dataItem,
                dataIndex,
                syntheticEvent: e.syntheticEvent,
                field: fieldName,
                value: newValue,
            });
            setInputValue(newValue);
        }
    };

    const customClassName = inputValue ? 'time-picker-cell' : 'invalid time-picker-cell';

    const defaultRendering = (
        <td className={className}>
            {dataItem.inEdit ? (
                <TimePicker
                    value={inputValue}
                    onChange={handleChange}
                    disabled={disabled}
                    format={TIME_FORMAT}
                    min={minTime}
                    className={customClassName}
                />
            ) : (
                <span>{inputValue ? moment(inputValue).format(TIME_FORMAT) : ''} </span>
            )}
        </td>
    );

    return render ? render(defaultRendering, props) : defaultRendering;
};

export default TimePickerCell;
